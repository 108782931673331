// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

const VIDEO1THRESHOLD = 1.4
const VIDEO2THRESHOLD = 2.8
const VIDEO3THRESHOLD = 4.5
let video1: HTMLVideoElement
let video2: HTMLVideoElement
let video3: HTMLVideoElement

observe('.js-packages-video-1', () => {
  video1 = document.querySelector<HTMLVideoElement>('.js-packages-video-1')!
  video1.play()
  video1.classList.remove('packages-video-hidden')

  video1.addEventListener('timeupdate', () => {
    if (video1.currentTime > VIDEO1THRESHOLD) {
      video2.play()
      video2.classList.remove('packages-video-hidden')
    }
  })
})

observe('.js-packages-video-2', () => {
  video2 = document.querySelector<HTMLVideoElement>('.js-packages-video-2')!
  video2.pause()

  video2.addEventListener('timeupdate', () => {
    if (video2.currentTime > VIDEO2THRESHOLD) {
      video3.play()
      video3.classList.remove('packages-video-hidden')
    }
  })
})

observe('.js-packages-video-3', () => {
  video3 = document.querySelector<HTMLVideoElement>('.js-packages-video-3')!
  video3.pause()

  video3.addEventListener('timeupdate', () => {
    if (video3.currentTime > VIDEO3THRESHOLD) {
      video1.currentTime = 0
      video1.play()
      video3.classList.add('packages-video-hidden')
      video3.pause()
      video3.currentTime = 0

      setTimeout(() => {
        video2.classList.add('packages-video-hidden')
        video2.pause()
        video2.currentTime = 0
      }, 160)
    }
  })
})
